import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useInScreen } from '@client/core/hooks';
import { getAdConfig, isFeatureEnabled } from '@client/core/state/reducer';
import {
  AdServer,
  GamConfig,
  PlacementId,
  Slot,
  debugLog,
  GamPlacement,
  UNLEASH_FEATURE_NAME,
  GamEventListenerEventName,
  getSizesByMediaType
} from '@schibsted-nmp/advertising-shared';

import { setTargetingOnSlotOrGlobal } from '../targeting';
import { getDebouncedLoadAdFunction } from '../batch';
import { $gamTargetingAtom } from '../../core/atoms/config';

type Props = {
  containerId: string;
  placementId: PlacementId;
  ref: RefObject<HTMLDivElement>;
  forecasting?: boolean;
};
type GamRequestStatus = 'pending' | 'loaded' | 'requested' | 'error';

const setupGamEventListeners = () => {
  const events: GamEventListenerEventName[] = [
    'slotRequested',
    'slotRenderEnded',
    'impressionViewable',
    'slotVisibilityChanged',
    'slotOnload'
  ];
  events.forEach((eventType) => {
    window.googletag
      .pubads()
      .addEventListener(eventType, (event) =>
        debugLog(`${eventType} event:`, event)
      );
  });
};

function setDevPanelData(placementId: PlacementId, text: string) {
  const localEntry = document.getElementById(`${placementId}-local-entry`);
  if (localEntry) {
    localEntry.innerHTML = `${placementId} <p class='m-0 text-12'> - ${text}</p>`;
  }
}

function ensurePathStartsWithSlash(path: string): string {
  if (!path.startsWith('/')) {
    return `/${path}`;
  }
  return path;
}

export function useInitiateGamUnit(props: Props) {
  const { placementId } = props;
  const [status, setStatus] = useState<GamRequestStatus>('pending');
  const adIsInView = useShouldLoadAd(props);
  const slotRef = useRef<Slot | null>(null);

  const { gamConfig, placement } = useMemo<{
    gamConfig: GamConfig;
    placement?: GamPlacement;
  }>(() => {
    const config = getAdConfig();
    // We get forecastingPlacements from the server if we have the active feature flag
    const placements =
      props.forecasting && config?.forecastingPlacements?.length > 0
        ? config?.forecastingPlacements
        : config?.placements;
    const placement = placements?.find(
      (p) => p.placementId === placementId && p.adServer.type === AdServer.GAM
    ) as GamPlacement | undefined;
    const gamConfig = config?.adServer.gam as GamConfig;
    return { gamConfig, placement };
  }, [placementId]);

  /**
   * This loads the ads in a batch
   */
  const sendToRequestBatchAds = useCallback((slot: Slot | null) => {
    if (slot) {
      getDebouncedLoadAdFunction()(slot);
    }
  }, []);

  /**
   * Load the ad when ready
   */
  useEffect(() => {
    let unsubscribeGamTargeting = () => {};
    if (adIsInView && status !== 'loaded' && slotRef.current) {
      setStatus('loaded');

      // Subscribe doesn't work with callback functions so we need to use it directly
      unsubscribeGamTargeting = $gamTargetingAtom.subscribe(() => {
        sendToRequestBatchAds(slotRef.current);
      });

      setDevPanelData(placementId, 'Google ad loaded');
    } else if (status !== 'loaded' && slotRef.current) {
      setDevPanelData(placementId, 'Slot defined');
    } else if (status !== 'loaded') {
      setDevPanelData(placementId, 'Slot not defined');
    }
    return () => {
      unsubscribeGamTargeting();
    };
  }, [status, adIsInView, slotRef.current, sendToRequestBatchAds]);

  const getSlot = useCallback(
    (placement: GamPlacement) => {
      const placementConfig = placement.adServer.config;
      const sizes =
        (placementConfig.sizes as number[][]) ||
        (getSizesByMediaType(placementConfig.mediaTypes) as number[][]);

      const placementConfigPath = ensurePathStartsWithSlash(
        placementConfig.path
      );
      const slot = window.googletag
        .defineSlot(placementConfigPath, sizes, placement.placementId)
        .addService(window.googletag.pubads());

      const { targeting } = placementConfig;
      if (targeting?.length > 0) {
        setTargetingOnSlotOrGlobal({
          slot,
          targeting,
          global: false
        });
      }

      if (
        isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamForecasting) &&
        props.forecasting
      ) {
        slot?.setTargeting('forecasting', 'true');
      }
      if (isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamTestCampaign)) {
        slot?.setTargeting('gamTestCampaign', 'true');
      }

      return slot;
    },
    [placementId, props.forecasting]
  );
  /**
   * Set up the ad
   */
  useEffect(() => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    if (placement && !slotRef.current) {
      // TODO: Implement consent handling with getConsentStatusOrSubscribe, same as with Xandr?
      window.googletag.cmd.push(() => {
        // Check if the slot is already defined and destroy it if necessary
        if (
          window.googletag
            .pubads()
            .getSlots()
            .some(
              (slot: any) => slot.getSlotElementId() === placement.placementId
            )
        ) {
          // slot already exists
          return;
        }
        debugLog('Defining Slot for placement: ', placementId);
        const slot = getSlot(placement);

        if (slot) {
          slotRef.current = slot;
          debugLog('Gam successfully set-up for placement:', placementId);

          // Add event listeners for ad events
          setupGamEventListeners();
        }
      });
    }
  }, [placement, gamConfig, placementId, getSlot, slotRef.current]);

  return { placement, status };
}

function getInScreenData(
  placementId: PlacementId
): { threshold: number; rootMargin: string } | null {
  switch (placementId) {
    case PlacementId.Right1:
      return null;
    case PlacementId.Top1:
      return {
        threshold: 0,
        rootMargin: '200px 0px 0px 0px'
      };
    default:
      return {
        threshold: 0,
        rootMargin: '100px 0px 0px 0px'
      };
  }
}

function useShouldLoadAd({ placementId, ref }: Props) {
  const inScreenData = useMemo(
    () => getInScreenData(placementId),
    [placementId]
  );

  const { hasIntersected } = useInScreen({ ref, ...(inScreenData || {}) });

  return !inScreenData || hasIntersected;
}
